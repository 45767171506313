@mixin button(
  $padding: 6px 10px,
  $font-size: 12px,
  $border-radius: 4px,
  $bg-color: #ffffff,
  $font-color: #000000
) {
  padding: $padding;
  display: inline-block;
  border: none;
  border-radius: $border-radius;
  background: $bg-color;
  font-size: $font-size;
  color: $font-color;
  cursor: pointer;
}

.MuiTableRow-root .MuiIconButton-root.Mui-disabled {
  visibility: hidden;
  display: none;
}

.MD100 .MuiTableRow-root .MuiTableCell-root:first-child {
  display: none;
  width: 0;
}

._loading_overlay_content {
  > div {
    margin: 0 auto;
  }
}

#map {
  display: flex;
  flex: 1 1 auto;
  // height: 600px;
  // height: 55vh;
  height: 100%;
  width: 49vw;

  &.dark {
    .details,
    .leaflet-popup-tip,
    .leaflet-popup-content-wrapper {
      background-color: #1e1e1e;
      color: #ffffff;
    }

    .leaflet-popup-content {
      button {
        @include button;
      }
    }
  }

  &.light {
    .details,
    .leaflet-popup-tip,
    .leaflet-popup-content-wrapper {
      background-color: #ffffff;
      color: #000000;
    }

    .leaflet-popup-content {
      button {
        @include button(6px 10px, 12px, 4px, #5945cc, #ffffff);
      }
    }
  }
}

#datatabpanel {
  display: flex;
  flex: 1 1 auto;
  // height: 600px;
  height: 90vh;
  width: 50vw;
}
#stateDataPanel {
  // display: flex;
  // flex: 1 1 auto;
  // height: 600px;
  // height: 40vh;
  //  width: 49vw;
  margin-top: 0px;
  padding-bottom: 0px;
  max-height: 50vh;
  overflow: auto;
  //  width: 49vw;
}
#plotDataPanel {
  // display: flex;
  // flex: 1 1 auto;
  // height: 600px;
  // height: 40vh;
  //  width: 49vw;
  margin-top: 0px;
  padding-bottom: 0px;
  max-height: 30vh;
  height: 30vh;
  //  overflow: auto;
  //  width: 49vw;
}

// margin-top: 10px;
// max-height: 300px;
// overflow: auto;

.details {
  padding: 15px;

  ul {
    text-align: left;
    padding-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .directional {
    display: flex;

    .direction-title {
      border-bottom: solid 1px;
    }

    .direction-1 {
      margin-right: 10px;
    }
  }
}

.current {
  padding: 8px 16px;
}

.logo-wrapper {
  margin-top: auto !important;
}

.logo {
  height: 37px;
  width: 130px;
  padding: 0 16px;
}

.link-disabled {
  text-decoration: line-through !important;
}

.leaflet-container {
  width: 100%;
}

.home-button {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 2px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  margin-left: 0 !important;
  padding: 3px !important;
}

.table-wrap,
.table-null {
  margin-bottom: 0 !important;
}

.table-null {
  padding: 16px;
}

.custom-block {
  font-size: 0.75rem;
}
.custom-block .custom-block-title {
  font-weight: 600;
  margin-bottom: -0.4rem;
}

.from-to-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
